import React, {
  useState, PropsWithChildren, cloneElement, useCallback, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import Tabs from '@common/components/tabs';
import { UnsplashInput } from '../unsplash';
import { FileInput } from '../file/file-input';
import { FileInputOwnProps } from '../file/utils';

export type FileInputModalProps = PropsWithChildren<FileInputOwnProps & {
  title?: string;
}>;

const FileInputModal = ({
  title,
  unsplash,
  children,
  onChange,
  onError,
  ...props
}: FileInputModalProps) => {
  const { t } = useTranslation();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const hasMaxAmountOfFiles = Array.isArray(props.value) &&
    typeof props.multiple === 'number' &&
    props.value.length >= props.multiple;

  const onChangeHandler = useCallback((...args) => {
    setIsVisibleModal(false);
    onChange(...args);
  }, [onChange, setIsVisibleModal]);

  const onErrorHandler = useCallback((args) => {
    if (onError) {
      setIsVisibleModal(false);
      onError(args);
    }
  }, [setIsVisibleModal, onError]);

  const fileInput = useMemo(() => {
    return (
      <FileInput
        {...props}
        disabled={hasMaxAmountOfFiles}
        onChange={onChangeHandler}
        onError={onErrorHandler}
      />
    );
  }, [props, onChangeHandler, onErrorHandler]);

  return (
    <Modal
      list
      show={isVisibleModal}
      className="PicturePickerModal"
      title={title || t('common:form_input_file_upload_photo_video')}
      onClose={() => setIsVisibleModal(false)}
      content={
        unsplash ? (
          <Tabs variant="full_width">
            <Tabs.Item title={t('common:form_input_file_upload')}>
              <div className="Form__FileInput__Trigger">
                {fileInput}
              </div>
            </Tabs.Item>
            <Tabs.Item title={t('common:form_input_file_image')}>
              <UnsplashInput
                processFile={props.processFile}
                value={props.value}
                onChange={onChangeHandler}
              />
            </Tabs.Item>
          </Tabs>
        ) : (
          fileInput
        )
      }
    >
      {cloneElement(children as React.ReactElement, {
        onClick: () => setIsVisibleModal(!isVisibleModal),
        disabled: hasMaxAmountOfFiles,
      })}
    </Modal>
  );
};

export default FileInputModal;
