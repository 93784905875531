import React from 'react';
import { omit } from 'lodash';
import {
  BaseFieldProps,
  Field,
  FieldArray,
  WrappedFieldArrayProps,
  WrappedFieldProps,
} from 'redux-form';

import FileInputModal, { FileInputModalProps } from './file-input-modal';
import {
  FileInputHookFormProps, getFileInputHookFormProps, getMultipleFileInputFormProps,
} from '../file/utils';

export type Props = Omit<FileInputModalProps, 'value' | 'onChange'>;

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const FileInputModalHookForm = (props: FileInputHookFormProps) => (
  <FileInputModal {...getFileInputHookFormProps(props)} />
);

const ReduxFormField = (props: WrappedFieldProps & Props) => {
  return (
    <FileInputModal
      {...props.input}
      {...omit(props, ['meta', 'input'])}
    />
  );
};

const ReduxFormFieldMultiple = (props: WrappedFieldArrayProps & Props) => (
  <FileInputModal {...getMultipleFileInputFormProps(props)} />
);

export default (props: Omit<BaseFieldProps<FileInputModalProps>, 'component'> & FileInputModalProps) => (
  props.multiple ?
    <FieldArray {...props} component={ReduxFormFieldMultiple} /> :
    <Field {...props} component={ReduxFormField} />
);
